import { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import html2canvas from "html2canvas";
import {
  getChartSpots,
  calculateNeighbours,
  getPatternDevChartSpots,
  getTotalChartSpots,
  getGazeChartData,
  getExamAlgorithmTranslation,
} from "../../utils/examReportsHelper";
import { getDecimalNum } from "../../utils/numberHelper";
import { ErrorMessageComponent } from "../../components/UI/ErrorMessage";
import TotalGraph from "../Report/VisualFieldReport/EyeReport/TotalGraph";
import NewPatternGraph from "../Report/VisualFieldReport/EyeReport/NewPatternGraph";
import NumericalGraph from "../Report/VisualFieldReport/EyeReport/NumericalGraph";
import { getLocalTime } from "../../utils/dateHelper";
import { ReportInfoShared } from "../../components/UI/ReportInfoShared";

const ProgressAnalysisEyeReport = ({ examReport, examData }) => {
  const { t } = useTranslation();

  const [imageSrc, setImageSrc] = useState(null);
  const [chartData, setChartData] = useState({
    number: undefined,
    pattern: undefined,
    totalDev: undefined,
    patternDev: undefined,
    gaze: undefined,
  });

  const eyeReport = examReport?.exam?.eye;
  const examStats = examReport?.stats;
  const postProcessing = examReport?.postProcessing;
  const isNotRlFast = examReport?.exam?.algorithm !== "SCREEN_FAST";

  const gridSizeData = useMemo(
    () => [
      { sm: 4, md: 4, lg: 4, xl: 4 },
      { sm: 5, md: 5.5, lg: 5, xl: 5.5 },
      { sm: 3, md: 2.5, lg: 3, xl: 2.5 },
    ],
    []
  );

  const infoData = useMemo(
    () => [
      [
        {
          title: t("progress_analysis_exam_algorithm"),
          body: t(getExamAlgorithmTranslation(examData)),
        },
      ],
      [
        {
          title: t("progress_analysis_exam_grid_type"),
          body: examReport?.exam?.grid,
        },
      ],
      [
        {
          title: t("report_exam_date"),
          body: getLocalTime(examReport?.timing?.end),
          isBlack: true,
        },
      ],
    ],
    [examData, examReport, t]
  );

  const handleShowImage = useCallback(async () => {
    const element = document.getElementById(`eye-report-${examData?.id}`);
    if (!element) {
      console.error("Element not found!");
      return;
    }

    try {
      const canvas = await html2canvas(element, { scale: 2 });
      setImageSrc(canvas.toDataURL("image/jpg"));
    } catch (error) {
      console.error("Error generating image:", error);
    }
  }, [examData?.id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleShowImage();
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [handleShowImage]);

  useEffect(() => {
    if (!examReport) return;

    const spots = getChartSpots(examReport);
    const finalPatternSpots = calculateNeighbours(spots);
    const { gazeSpots } = getGazeChartData(examReport);

    setChartData({
      number: spots,
      pattern: finalPatternSpots,
      totalDev: getTotalChartSpots(examReport),
      patternDev: getPatternDevChartSpots(examReport),
      gaze: gazeSpots,
    });
  }, [examReport]);

  if (!eyeReport || !examData || !examReport) return null;

  return (
    <div className="retinalogik-section" style={{ width: "100%" }}>
      {postProcessing ? (
        imageSrc ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <img
              className="retinalogik-report"
              src={imageSrc}
              alt="Generated"
              width="100%"
              height="auto"
            />
          </div>
        ) : (
          <Grid
            data-testid={`eye-report-${examData?.id}`}
            id={`eye-report-${examData?.id}`}
            container
            gap={2}
            sx={{
              width: "100%",
              whiteSpace: "nowrap",
            }}
          >
            {isNotRlFast && (
              <>
                <Grid
                  container
                  display="flex"
                  justifyContent="space-between"
                  border="1px solid black"
                  borderRadius="4px 4px 0px 0px"
                  p={1}
                >
                  <ReportInfoShared
                    data={infoData}
                    gridSizeData={gridSizeData}
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="space-around"
                  rowSpacing={2}
                  border="1px solid black"
                  borderRadius="0px 0px 4px 4px"
                  borderTop="none"
                >
                  <Grid item xs pb={1}>
                    <Paper
                      elevation={0}
                      sx={{
                        textAlign: "center",
                        height: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {t("Greyscale")}
                      </Typography>
                      <div
                        style={{
                          transform: "scale(0.8)",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <NewPatternGraph
                          pressedPoints={chartData.pattern || []}
                          eye={eyeReport}
                          gridType={examReport?.exam?.grid}
                        />
                      </div>
                      <Box display="flex" justifyContent="space-around">
                        <Typography
                          data-testid={`mean-deviation-${examData.id}`}
                          sx={{ fontWeight: "bold" }}
                        >
                          MD:{" "}
                          {postProcessing?.totalMeanDeviation !== null
                            ? `${getDecimalNum(postProcessing?.totalMeanDeviation, 2)} dB`
                            : "N/A"}
                        </Typography>
                        <Typography
                          data-testid={`pattern-standard-deviation-${examData.id}`}
                          sx={{ fontWeight: "bold" }}
                        >
                          PSD:{" "}
                          {postProcessing?.patternStandardDeviation !== null
                            ? `${getDecimalNum(postProcessing?.patternStandardDeviation, 2)} dB`
                            : "N/A"}
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>

                  <Grid item xs pb={1}>
                    <Paper
                      elevation={0}
                      sx={{
                        textAlign: "center",
                        height: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {t("Total Deviation")}
                      </Typography>
                      <div
                        style={{
                          transform: "scale(0.8)",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          height: "80%",
                        }}
                      >
                        <NumericalGraph
                          processedPoints={
                            postProcessing?.processedPoints || []
                          }
                          gridType={examReport?.exam?.grid}
                        />
                      </div>
                      <Box display="flex" justifyContent="space-around">
                        <Typography
                          data-testid={`false-positive-${examData.id}`}
                          sx={{ fontWeight: "bold" }}
                        >
                          FP:{" "}
                          {examStats?.falsePositivePercentage
                            ? `${getDecimalNum(examStats?.falsePositivePercentage, 2)} %`
                            : "N/A"}
                        </Typography>
                        <Typography
                          data-testid={`fixation-loss-${examData.id}`}
                          sx={{ fontWeight: "bold" }}
                        >
                          FL:{" "}
                          {examStats?.fixationLossPercentage !== null
                            ? `${getDecimalNum(examStats?.fixationLossPercentage, 2)} %`
                            : "N/A"}
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>

                  <Grid item xs pb={1}>
                    <Paper
                      elevation={0}
                      sx={{
                        textAlign: "center",
                        height: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {t("Total Deviation Percentile")}
                      </Typography>
                      <div
                        style={{
                          transform: "scale(0.9)",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          height: "70%",
                        }}
                      >
                        <TotalGraph
                          pressedPoints={chartData.totalDev || []}
                          eye={eyeReport}
                          gridType={examReport?.exam?.grid}
                        />
                      </div>
                      <Box display="flex" justifyContent="space-around">
                        <Typography
                          data-testid={`retinalogik-field-index-${examData.id}`}
                          sx={{ fontWeight: "bold" }}
                        >
                          RLFI:{" "}
                          {postProcessing?.retinaLogikFieldIndex !== null
                            ? `${getDecimalNum(postProcessing?.retinaLogikFieldIndex, 2)} %`
                            : "N/A"}
                        </Typography>
                        <Typography
                          data-testid={`false-negative-${examData.id}`}
                          sx={{ fontWeight: "bold" }}
                        >
                          FN:{" "}
                          {examStats?.falseNegativeCount !== null
                            ? `${examStats?.falseNegativeCount} %`
                            : "N/A"}
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        )
      ) : (
        <ErrorMessageComponent errorBit={1} />
      )}
    </div>
  );
};

export default ProgressAnalysisEyeReport;
