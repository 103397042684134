// Library methods
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Alert, IconButton } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// Styles
import {
  ModalCustomCancel,
  ModalCustomConfirmDelete,
  ModalPaperProps,
  ModalCustomConfirmIconHandler,
  ModalDialogActions,
} from "../../../styles/muiStylesHelper";

const SharedDeleteModal = ({
  open,
  onClose,
  onConfirm,
  onCancel,
  titleKey,
  descriptionKey,
  customStyles = {},
  progressionAnalysisFound,
  progressionAnalysisFoundCount,
}) => {
  // internationalization
  const { t } = useTranslation();

  // Modal fullScreen on small screens
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      PaperProps={{
        sx: { ...ModalPaperProps, ...customStyles },
      }}
    >
      <DialogContent sx={{ padding: "20px 20px 0" }}>
        <Box sx={{ padding: 1 }}>
          <Box display="flex" sx={{ alignItems: "center" }}>
            <IconButton
              aria-label="delete-icon"
              size="small"
              sx={{
                color: "#C11717",
                width: 25,
                height: 25,
                mr: 1,
                backgroundColor: "rgba(193, 23, 17, 0.4)",
                "&:hover": {
                  color: "#C11717",
                  backgroundColor: "rgba(193, 23, 17, 0.4)",
                  borderColor: "#202338",
                },
              }}
            >
              <DeleteForeverIcon fontSize="inherit" />
            </IconButton>
            <Typography
              variant="h5"
              component="h2"
              sx={{ fontWeight: "700", color: "#C11717" }}
            >
              {t(titleKey)}
            </Typography>
          </Box>
          <Typography sx={{ mt: 3 }}>{t(descriptionKey)}</Typography>
        </Box>
      </DialogContent>

      {progressionAnalysisFound && (
        <Box padding={1}>
          <Alert
            variant="outlined"
            severity="warning"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography>
              {progressionAnalysisFoundCount === 1
                ? t("analysis_status_check_warning")
                : t("analysis_status_check_warning_for_plural")}
            </Typography>
          </Alert>
        </Box>
      )}

      <DialogActions sx={ModalDialogActions}>
        <Button variant="text" sx={ModalCustomCancel} onClick={onCancel}>
          {t("button_cancel")}
        </Button>
        <Button
          variant="outlined"
          sx={{
            ...ModalCustomConfirmDelete(),
            ...ModalCustomConfirmIconHandler(),
          }}
          onClick={onConfirm}
        >
          {t("button_delete")}
          <DeleteForeverIcon fontSize="medium" color="inherit" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SharedDeleteModal;
