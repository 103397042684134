import { Box, Grid, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import useClinic from "../../../hooks/useClinic";
import ActionableSearchBar from "../../../components/UI/ActionableSearchBar";
import ProgressionAnalysisCard from "../../../components/UI/ProgressionAnalysisCard";
import ListHeader from "../../../components/UI/ListHeader";
import {
  deleteProgressionAnalysis,
  getAllProgressionAnalysis,
} from "../../../services/Exam";
import { itemsRendering, pagesCount } from "../../../utils/paginationHelper";
import PaginationWrapper from "../../../components/UI/PaginationWrapper";
import Loader from "../../../components/UI/Loader";
import DeleteProgressionAnalysisModal from "../../../components/UI/DeleteProgressionAnalysisModal";
import { ToastContext } from "../../../contexts/ToastContext";
import SnackbarMessage from "../../../components/UI/SnackbarMessage";
import { getLocalTime } from "../../../utils/dateHelper";

const ProgressionAnalysisList = ({
  patientId,
  progressAnalysisOnClick = () => {},
  maxNumOfRows = 4,
  patientName,
}) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { clinicId, clinicLicensing } = useClinic();
  const { toast, setToast } = useContext(ToastContext);

  const [progressionAnalysisList, setProgressionAnalysisList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("creationDate");

  const headCells = [
    {
      id: "empty",
      label: null,
      gridSize: 0,
      orderUsing: ["eye"],
    },
    {
      id: "eye",
      label: t("exams_column_eye"),
      gridSize: 1.5,
      orderUsing: ["eye"],
    },
    {
      id: "completionDate",
      label: t("completion_date_range"),
      gridSize: 6,
      orderUsing: ["completionDate"],
    },
    {
      id: "creationDate",
      label: t("creation_date"),
      gridSize: 3.5,
      orderUsing: ["creationDate"],
    },
  ];

  const extraCells = [
    {
      id: "manage",
      label: "",
      gridSize: 1,
    },
  ];

  const onFilterChange = useCallback(
    (event) => {
      if (page > 1) setPage(1);
      setSearchTerm(event.target.value);
    },
    [page]
  );

  // Sorting logic
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Sorting rows
  const sortedRows = useMemo(() => {
    return progressionAnalysisList.slice().sort((a, b) => {
      if (order === "asc") {
        return a[orderBy] > b[orderBy] ? 1 : -1;
      } else {
        return a[orderBy] < b[orderBy] ? 1 : -1;
      }
    });
  }, [progressionAnalysisList, order, orderBy]);

  // Filtering rows
  const filteredRows = useMemo(() => {
    return sortedRows.filter((progressionAnalysis) => {
      return !(
        searchTerm?.length &&
        !(
          progressionAnalysis?.eye
            ?.toLowerCase()
            ?.includes(searchTerm.toLowerCase()) ||
          progressionAnalysis?.creationDate
            ?.toLowerCase()
            ?.includes(searchTerm.toLowerCase()) ||
          `${progressionAnalysis?.earliestCompletionLocalDate} - ${progressionAnalysis?.latestCompletionLocalDate}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      );
    });
  }, [sortedRows, searchTerm]);

  const rowsToRender = useMemo(
    () => itemsRendering(filteredRows, page, Math.max(maxNumOfRows, 4)),
    [filteredRows, maxNumOfRows, page]
  );

  const handleCheckboxClick = useCallback(
    (id) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected;

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    },
    [selected]
  );

  const isSelected = useCallback(
    (id) => selected.indexOf(id) !== -1,
    [selected]
  );

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelecteds = rowsToRender.map((n) => n.id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    [rowsToRender]
  );

  const handleDeleteClick = useCallback(() => {
    setDeleteModal(true);
  }, []);

  const handleDeleteClose = useCallback((event, reason) => {
    if (reason !== "backdropClick") {
      setDeleteModal(false);
    }
  }, []);

  const DeleteProgressionAnalysis = useCallback(async () => {
    setDeleteModal(false);

    const generateDeletePromise = async (id) => {
      const token = await getAccessTokenSilently();
      await deleteProgressionAnalysis(token, clinicId, patientId, id);
    };

    // delete from api
    try {
      const deletePromises = selected.map((id) => generateDeletePromise(id));
      await Promise.all(deletePromises);

      setToast(() => ({
        success: true,
        message: t("progression_analysis_delete_success"),
      }));

      const newRows = progressionAnalysisList.filter(
        (progressionAnalysis) => !selected.includes(progressionAnalysis.id)
      );

      setProgressionAnalysisList([...newRows]);

      setSelected([]);
    } catch (error) {
      setToast(() => ({
        success: false,
        message: t("progression_analysis_delete_error"),
      }));
    }
  }, [
    clinicId,
    getAccessTokenSilently,
    patientId,
    progressionAnalysisList,
    selected,
    setToast,
    t,
  ]);

  const clearForm = useCallback(() => {}, []);

  const handleViewReport = useCallback(
    (patientId, progressionAnalysisId) => {
      navigate(
        `/patients/${patientId}/progression-analysis/${progressionAnalysisId}`
      );
    },
    [navigate]
  );

  const onPaginationChange = useCallback((_, value) => {
    setPage(value);
  }, []);

  const clearSearchFilter = useCallback(() => {
    setSearchTerm("");
  }, []);

  // Fetching data
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const res = await getAllProgressionAnalysis(token, clinicId, patientId);
        const formattedList = res?.map((progressionAnalysis) => {
          let earliest,
            latest = null;
          return {
            ...progressionAnalysis,
            exams:
              progressionAnalysis?.exams?.map((exam) => {
                if (exam?.completionDate) {
                  const completionDate = new Date(exam.completionDate);
                  const earliestDate = earliest ? new Date(earliest) : null;
                  const latestDate = earliest ? new Date(earliest) : null;
                  if (earliestDate === null || completionDate < earliestDate)
                    earliest = exam.completionDate;
                  if (latestDate === null || completionDate > latestDate)
                    latest = exam.completionDate;
                }
                return exam;
              }) ?? [],
            earliestCompletionLocalDate: earliest
              ? getLocalTime(earliest)
              : "N/A",
            latestCompletionLocalDate: latest ? getLocalTime(latest) : "N/A",
          };
        });
        setProgressionAnalysisList(formattedList);
        setIsLoading(false);
      } catch (e) {}
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        item
        display={"flex"}
        alignItems={"center"}
        xs={12}
        justifyContent={"right"}
        gap={2}
        mb={{ xs: 1, sm: 0 }}
        mt={1}
      >
        <ActionableSearchBar
          placeholder={`${t("word_search")}...`}
          onSearchChange={onFilterChange}
          onClear={clearSearchFilter}
          showDeleteButton={selected.length > 0}
          disableDeleteButton={!selected.length}
          deleteButtonOnClick={handleDeleteClick}
          showAddButton={clinicLicensing?.canUseProgressionReports ?? false}
          addButtonOnClick={() => {
            clearForm();
            progressAnalysisOnClick();
          }}
          selectedRows={selected}
        />
      </Grid>
      {isLoading ? (
        <Loader containerHeight="30vh" />
      ) : (
        <Grid container mt={{ xs: 0, sm: 3 }}>
          <Grid item xs={12}>
            <ListHeader
              containerPaddingX={0}
              headCells={headCells}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rowsToRender.length}
              checkBoxGrid={1}
              shouldDisplayGrid="sm"
              displayFirtsBeforeCheckbox
              extraCells={extraCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {rowsToRender.length > 0 ? (
              <Box mt={1}>
                {rowsToRender.map((row) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <ProgressionAnalysisCard
                      key={row?.id}
                      row={row}
                      patientId={patientId}
                      isSelected={isItemSelected}
                      headCells={headCells}
                      handleCheckboxClick={handleCheckboxClick}
                      handleViewReportClick={handleViewReport}
                    />
                  );
                })}
              </Box>
            ) : (
              <Typography
                variant="h6"
                color="text.secondary"
                textAlign={"center"}
                py={15}
                sx={{ wordBreak: "break-all" }}
              >
                {`${patientName} ${t("has_no_progression_analysis")}`}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
      {progressionAnalysisList.length > rowsToRender.length && (
        <PaginationWrapper
          page={page}
          count={pagesCount(progressionAnalysisList, Math.max(maxNumOfRows, 4))}
          onChange={onPaginationChange}
        />
      )}

      <DeleteProgressionAnalysisModal
        open={deleteModal}
        onClose={handleDeleteClose}
        onConfirm={DeleteProgressionAnalysis}
        onCancel={handleDeleteClose}
      />

      <SnackbarMessage
        open={Boolean(toast.message)}
        onClose={() => setToast({ success: false, message: null })}
        success={toast.success}
        autoHideDuration={8000}
        text={toast.message}
      />
    </>
  );
};

export default ProgressionAnalysisList;
