import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Card, Grid } from "@mui/material";
import { Checkbox } from "@mui/material";
import { getLocalTime } from "../../../../utils/dateHelper";

export default function SelectAnalysisCard({
  exam,
  isSelected,
  selectedExam,
  handleCardSelect,
}) {
  const { t } = useTranslation();

  const examTitle = exam?.visualFieldSections?.[0]?.algorithm.replace(
    /_/g,
    " "
  );
  const examGridType = exam?.visualFieldSections?.[0]?.gridType.slice(2);

  const leftSideLabels = [
    { label: "FL", field: "fixationLossPercentage" },
    {
      label: "FN",
      fields: ["falseNegativeCount", "falseNegativeMisses"],
    },
    { label: "FP", field: "falsePositivePercentage" },
  ];

  const rightSideLabels = [
    { label: "PSD", field: "patternStandardDeviation" },
    { label: "MD", field: "totalMeanDeviation" },
    { label: "RLFI", field: "retinaLogikFieldIndex" },
  ];

  return (
    <Card
      key={exam.id}
      sx={{
        minWidth: 300,
        backgroundColor: isSelected ? "#E2772E" : "#1E1E2F",
        color: "#FFF",
        borderRadius: 2,
        padding: 2,
        transition: "background-color 0.3s ease",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
      }}
      onClick={() => {
        handleCardSelect(exam);
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Checkbox
          checked={isSelected}
          onChange={() => handleCardSelect(exam)}
          sx={{
            padding: 0,
            color: "#FFF",
            "&.Mui-checked": { color: "#fff" },
          }}
        />
        <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
          {examTitle} | {examGridType}
        </Typography>
      </Box>

      <Grid container gap={isSelected ? 2 : 0}>
        <Grid item xs={12}>
          <Box
            sx={{
              paddingTop: 1,
              paddingBottom: 0,
            }}
          >
            <Typography variant="body2">
              {t("progress_analysis_card_age")}: {exam.age}
            </Typography>
            <Typography variant="body2">
              {t("progress_analysis_exam_date")}:{" "}
              {getLocalTime(exam.creationDate)}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              {isSelected && selectedExam?.reportData?.stats && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {leftSideLabels.map(({ label, field, fields }, index) => {
                    let displayValue;

                    if (fields) {
                      const falseNegativeCount =
                        selectedExam.reportData?.stats[fields[0]];
                      const falseNegativeMisses =
                        selectedExam.reportData?.stats[fields[1]];

                      if (
                        falseNegativeCount !== null &&
                        !isNaN(falseNegativeCount) &&
                        falseNegativeMisses !== null &&
                        !isNaN(falseNegativeMisses)
                      ) {
                        displayValue = `${falseNegativeMisses || 0}/${falseNegativeCount || 0}`;
                      }
                    } else {
                      const value = selectedExam.reportData?.stats[field];

                      if (value !== null && !isNaN(value)) {
                        displayValue = Number(value).toFixed(2);
                      }
                    }

                    return displayValue ? (
                      <Typography key={index} variant="body2">
                        {label}: {displayValue}
                        {!fields && field !== "falseNegativeCount" && "%"}
                      </Typography>
                    ) : null;
                  })}
                </Box>
              )}
            </Grid>

            <Grid item xs={6}>
              {isSelected && selectedExam?.reportData.postProcessing && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {exam.visualFieldSections?.map((section, index) => (
                    <Box
                      key={section.id || index}
                      display="flex"
                      flexDirection="column"
                    >
                      {rightSideLabels.map(({ label, field }, index) => {
                        const value =
                          selectedExam.reportData.postProcessing[field];

                        if (value !== null && !isNaN(value)) {
                          return (
                            <Typography key={index} variant="body2">
                              {label}: {Number(value).toFixed(2)}
                              {field === "retinaLogikFieldIndex" && "%"}
                            </Typography>
                          );
                        }
                        return null; // Return null if the value is NaN or null
                      })}
                    </Box>
                  ))}
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
